import React from "react";
import Layout from "layout";
import { Link } from "gatsby";
import { Row, Col } from "react-grid-system";
import Seo from "common/seo";

import "./termsandconditions.scss";

function TermsAndConditionsPage({ pageContext }) {
  return (
    <Layout
      pageClass="page-termsandconditions"
      breadcrumbData={pageContext.breadcrumb}
    >
      <Seo
        pageTitle="Terms and Conditions"
        pageDescription="Read Beiersdorf's terms and conditions"
      />
      <div className="inner-body-content">
        <section className="inner-centered-container">
          <Row>
            <Col sm={12}>
              <h1>Terms and Conditions</h1>
              <p>
                Beiersdorf, Inc. (“Beiersdorf”) operates this website at{" "}
                <Link to="/" className="text-link">
                  www.BeiersdorfMD.com
                </Link>{" "}
                as a service to Beiersdorf customers and others who are
                interested in Beiersdorf products and services and reside in the
                United States of America, its territories and possessions.
              </p>
              <p>
                By visiting this website you accept, without limitation or
                qualification, the following terms and conditions:
              </p>
              <h2>Use of this website and its data </h2>
              <p>
                Unless expressly permitted by Beiersdorf in writing or elsewhere
                on this website, you may not copy, reproduce, distribute,
                publish, store, cache, disseminate, display, perform, modify,
                create derivative works from, transfer, sell or in any way
                exploit the content of this website. You may not attempt to
                obtain lists of users or gain access to personal information of
                users or others from or via this website. You also may not use
                spiders, crawlers or similar utilities or use any automatic
                means to access, log-in to the website to register regarding any
                of its features. You may not attempt to obtain unauthorized
                access to computer systems, data or any services made available
                on or via their website or use their website or the services
                made available on or via it to interrupt, damage, disable, flood
                or impair this website, or to interfere with any other party’s
                use and enjoyment of it. You may not use this website or its
                services or features in violation of any third party’s
                intellectual property or other proprietary or legal right, or of
                any applicable law. You may not attempt to circumvent, reverse
                engineer, decrypt or otherwise alter or interfere with this
                website.
              </p>
              <p>
                This website is intended for use only by persons located in the
                United States and in compliance with state and federal laws. You
                may not use this site from outside the United States. The
                materials on this site may not be appropriate for any location
                outside of the United States and the products, services, and
                information on this site apply only to the United States.
                Statements on sites intended for locations outside of the United
                States do not apply to products sold in the United States and
                should not be relied upon by any purchaser in the United States.
              </p>
              <h2>Updates</h2>
              <p>
                These terms of use may be periodically updated without notice to
                you. These terms of use shall apply in the form in which they
                shall be posted at the time that you shall visit in any
                instance. You are encouraged to check these terms of use
                regularly for updates.
              </p>
              <h2>Privacy policy</h2>
              <p>
                Beiersdorf takes the privacy of your personal information
                seriously. Please refer to the Privacy Policy of this website
                for more information regarding data collection and use.
              </p>
              <h2>Additional features</h2>
              <p>
                This website may offer certain special features, functions and
                activities that may be subject to terms of use, rules or
                policies in addition to, or in lieu of, these terms of use.
                Beiersdorf may notify you of the foregoing, but it is your
                responsibility, before participating in any such event, to read
                and understand all applicable terms, rules and policies related
                to it.
              </p>
              <p>
                In the event that visitors shall be permitted to post comments,
                testimonials and other content, for any content so posted, you
                grant Beiersdorf and its affiliates, a royalty-free,
                unrestricted, worldwide, perpetual, irrevocable, non-exclusive
                and fully transferable, assignable and sublicensable right and
                license to use, copy, reproduce, modify, adapt, publish,
                translate, create derivative works from, distribute, perform,
                display, make, sell that content, in whole or in part, in any
                form, media, or technology now known or later devised or
                perfected. You represent and warrant that you own or otherwise
                control all of the rights to any such content that you may be
                permitted to post; that the content shall be accurate and solely
                created by you; and that use of that content will not cause
                offense, injury or damage to any person or entity. You hereby
                indemnify Beiersdorf and its affiliates, website designers,
                promotional agencies and representatives from and against all
                claims, damages, costs, judgments and awards resulting from, or
                in connection with, any content you post.
              </p>
              <h2>Reviews</h2>
              <p>
                Beiersdorf may now or in the future offer users of this website
                the opportunity to create, build, post, upload, display,
                publish, distribute, transmit, or submit a product review
                through the website (a “Review”). Subject to the license you
                grant in these Terms of Use, you retain whatever legally
                cognizable right, title, and interest that you have in your
                Review and you remain ultimately responsible for them. Except as
                otherwise described in this website’s posted Privacy Policy, you
                agree that: (a) your Review will be treated as non-confidential
                – regardless of whether you mark it “confidential,”
                “proprietary,” or the like – and will not be returned; and (b)
                Beiersdorf does not assume any obligation of any kind to you or
                any third party with respect to your Review. Upon Beiersdorf’s
                request, you will furnish it with any documentation necessary to
                substantiate any claims made by you in a Review and your rights
                to such content, and to verify your compliance with these terms
                of use.
              </p>
              <p>
                You grant to Beiersdorf the non-exclusive, unrestricted,
                unconditional, unlimited, worldwide, irrevocable, perpetual, and
                cost-free right and license to use, copy, record, distribute,
                reproduce, disclose, sell, re-sell, sublicense (through multiple
                levels), display, publicly perform, transmit, publish,
                broadcast, translate, make derivative works of, remove, and
                otherwise use and exploit in any manner whatsoever, all or any
                portion of your Review (and derivative works thereof), for any
                purpose whatsoever in all formats, on or through any means or
                medium now known or hereafter developed, and with any technology
                or devices now known or hereafter developed, and to advertise,
                market, and promote the same. Without limitation, the granted
                rights include the right to: (a) configure, host, index, cache,
                archive, store, digitize, compress, optimize, modify, reformat,
                edit, adapt, publish in searchable format, and remove such
                Review and combine the same with other materials, and (b) use
                any ideas, concepts, know-how, or techniques contained in any
                Reviews for any purposes whatsoever, including developing,
                producing, and marketing products and/or services.
              </p>
              <p>
                In order to further effect the rights and license that you grant
                to Beiersdorf to your Review, you also hereby grant to
                Beiersdorf, and agree to grant to Beiersdorf, the unconditional,
                perpetual, irrevocable right to use and exploit your name,
                persona, image, voice, and other likeness in connection with any
                Review, without any obligation or remuneration to you. Except as
                prohibited by law, you hereby waive, and you agree to waive, any
                moral rights (including attribution and integrity) that you may
                have in any Review, even if it is altered or changed in a manner
                not agreeable to you.
              </p>
              <p>
                You understand that you will not receive any fees, sums,
                consideration, or remuneration for any of the rights granted in
                this section.
              </p>
              <p>
                By submitting a review, you acknowledge that Beiersdorf may use
                and reshare your review in accordance with these Terms of Use.
              </p>
              <h2>Intellectual property</h2>
              <p>
                This website, the Aquaphor and Eucerin brands and trademarks and
                other brands and marks of Beiersdorf and its affiliates, as well
                as their respective product names and trade dress are protected
                from unauthorized use by applicable law. You are not granted any
                right to use, and may not use, any of Beiersdorf’s copyrights,
                trademarks, patents or other intellectual property; however, you
                may download material from this website solely for personal,
                private, non-commercial use by you. No part of this website,
                including, without limitation, the text, designs, graphics,
                photographs and images contained in it, may be copied,
                reproduced, republished, uploaded, re-posted, modified,
                transmitted, distributed or otherwise used in any way for any
                non-personal, public or commercial purpose without the prior
                written consent of Beiersdorf.
              </p>
              <h2>Products</h2>
              <p>
                Beiersdorf attempts to be accurate in its product descriptions.
                However, Beiersdorf does not warrant that any product
                descriptions or other content on this website is accurate,
                complete, reliable, current or error free. The appearances of
                colors and other product characteristics may vary depending on
                the monitor you use and other technical factors. If the
                description of a product offered by Beiersdorf via this website
                is not materially accurate, your sole remedy is to return it in
                unused condition.
              </p>
              <h2>Authorized dealers</h2>
              <p>
                This website is an official website of Beiersdorf for the United
                States of America, its territories and possessions. To help
                ensure quality control, Beiersdorf products are sold at retail
                exclusively by authorized retailers. A number of our authorized
                retailers also maintain websites that are authorized to sell
                Beiersdorf products. This website may contain links to websites
                maintained by certain authorized retailers. If you obtain
                Beiersdorf products from any source other than from an
                authorized retailer, you do so at your own risk, and any
                Beiersdorf product guarantee may be void to the extent permitted
                by applicable law.
              </p>
              <h2>Suggestions</h2>
              <p>
                Beiersdorf welcomes your suggestions, ideas and other
                submissions about the website, its content and our products. If
                you believe that you have a proprietary right to any such
                information or you want to assert any proprietary right in it,
                without use by Beiersdorf, please do not submit it to this
                website or show it to Beiersdorf by any other means. If you want
                to send Beiersdorf any such material, you represent and warrant
                that it is original to you, that no other party has any rights
                to it, and that any “moral rights” in that material have been
                waived. You grant Beiersdorf and its affiliates a royalty-free,
                unrestricted, worldwide, perpetual, irrevocable, non-exclusive
                and fully transferable, assignable and sublicensable right and
                license to use, copy, reproduce, modify, adapt, publish,
                translate, create derivative works from, distribute, perform,
                display, make, sell and what you have sent, in whole or in part,
                in any form, media or technology now known or later devised or
                perfected.
              </p>
              <h2>Limitations on use</h2>
              <p>
                Use of this website is intended solely for informational
                purposes. This website is not intended to provide medical
                information or advice. If you believe that you have a medical
                condition or medical need that relates to, or could be affected
                by, the use of any Beiersdorf product or service, consult your
                health-care provider prior to use.
              </p>
              <p>
                Beiersdorf uses reasonable efforts to include only accurate and
                up-to-date information on this website; however, Beiersdorf
                makes no representations or warranties about any information
                provided by or through this website by Beiersdorf or any other
                party and assumes no liability for any errors or omissions.
                Beiersdorf reserves the right to change the information included
                in, and content of, this website, including these terms of use,
                without prior notice.
              </p>
              <p>
                This website may contain hyperlinks or references to websites
                from other providers which Beiersdorf has no control and to
                which these Terms do not extend (“Linked Sites”). These links
                are provided merely as a convenience. Beiersdorf is not aware of
                the content of third-party websites and assumes no warranty or
                liability for any illegal content or other legal infringements
                on third-party websites. Every care was taken in the compilation
                and validation of the information contained on this website.
                Nevertheless, Beiersdorf cannot assume any responsibility for
                its topicality, accuracy, completeness and/or quality.
              </p>
              <p>
                Beiersdorf has no responsibility for the content, products,
                services, advertising, code or other materials that you may
                access via any Linked Sites. Links to Linked Sites do not
                constitute an endorsement or sponsorship by Beiersdorf or its
                affiliates of whatever you may find on Linked Sites or via their
                use, access or perception. If you visit any Linked Site, you may
                be subject to the terms of use, privacy policies and other use
                documentation of those Linked Sites. The use of Linked Sites may
                be subject to laws and regulations inapplicable to this website
                in whole or part. You should investigate thoroughly the legal
                documentation posted on any Linked Sites prior to use of it.
              </p>
              <p>
                If you wish to link from any other website to this website, you
                must first obtain our prior written consent, which may
                subsequently be withdrawn by Beiersdorf at any time and for any
                reason. Your link must open in a new browser window or tab and
                link to the full version of the website. You are not permitted
                to use any linking method by which content on this website may
                be displaced on another website (a practice sometimes referred
                to as “in-line” linking). You may not link from any other
                website to this website in any manner such that this website, or
                any page of it, is “framed,” by any third-party material. You
                may not use any meta tags or any other “hidden text” utilizing
                any Beiersdorf trademark or other intellectual property without
                the express written consent of Beiersdorf. Any unauthorized use
                automatically terminates the permission to link granted by
                Beiersdorf.
              </p>
              <h2>Limitations of liability</h2>
              <p>
                BEIERSDORF AND ITS AFFILIATES ARE NOT LIABLE FOR ANY DIRECT,
                INCIDENTAL, CONSEQUENTIAL, INDIRECT OR PUNITIVE DAMAGES ARISING
                FROM YOUR USE OF THIS WEBSITE, ANY LINKED SITE OR OTHER WEBSITE
                ACCESSED THROUGH IT. WITHOUT LIMITING THE FOREGOING, THIS
                WEBSITE IS MADE AVAILABLE “AS IS,” WITHOUT ANY EXPRESS OR
                IMPLIED WARRANTY OF ANY KIND, INCLUDING, WITHOUT LIMITATION, THE
                IMPLIED WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR
                PURPOSE. BEIERSDORF DOES NOT WARRANT THAT THIS WEBSITE OR ANY
                LINKED SITES OR OTHER WEBSITES ACCESSED THROUGH IT ARE FREE OF
                VIRUSES OR OTHER HARMFUL COMPONENTS OR FEATURES. CERTAIN STATE
                LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE
                EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. SOME OR ALL OF THE
                ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS THEREFORE MAY NOT
                APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
              </p>
              <h2>Disputes</h2>
              <p>
                By visiting this website, you agree that the laws of the State
                of Connecticut, without regard to principles of conflict of
                laws, will govern these terms of use and any dispute of any kind
                that might arise between you and Beiersdorf related to them and
                your use of this website, including, without limitation, product
                purchases. Unless prohibited by applicable law, any such dispute
                shall only be adjudicated in any state or federal court in
                Fairfield County, Connecticut, and you consent to exclusive
                jurisdiction and venue in those courts.
              </p>
              <h2>Contact us</h2>
              <p>
                Beiersdorf Inc.
                <br />
                301 Tresser Blvd, 15th Floor
                <br />
                Stamford, CT 06902
                <br />
                Tel: 1-800-227-4703
                <br />
                Email:{" "}
                <a
                  href="mailto:consumerrelations@beiersdorf.com"
                  className="text-link"
                >
                  consumerrelations@beiersdorf.com
                </a>
              </p>
            </Col>
          </Row>
        </section>
      </div>
    </Layout>
  );
}

export default TermsAndConditionsPage;
